import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import { useIntl } from "gatsby-plugin-intl";
import HeaderComponent from "../components/HeaderComponent";
import NewsItemCard from "../components/News/NewsCard";

const BlogOverview = (props) => {
  const intl = useIntl();
  const newsItems = props.pageContext.newsItems;
  return (
    <Layout>
      <Helmet title={`Blog| ${config.siteTitle}`} />
      <HeaderComponent
        title={intl.formatMessage({ id: "blog" })}
        background="#e9f2fa;"
      />
      <NewsItemsContainer>
        {newsItems.map((item) => {
          return <NewsItemCard key={item.id} {...item} />;
        })}
      </NewsItemsContainer>
    </Layout>
  );
};

const NewsItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  justify-content: space-between;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export default BlogOverview;
